const service = {

  signup: "users/register",
  emailotpverify: "users/emailotpverify",
  resendCode: "users/resendCode",
  signin: "users/login",
  logout: "users/logout",
  adminlogin: "adminapi/login",
  adminlogg: "adminapi/adminlogg",
  verify_otp: "adminapi/verify_otp",

  userscount: "adminapi/userscount",
  depositcount: "adminapi/depositcount",
  withdrawcount: "adminapi/withdrawcount",
  activatedUserList: "adminapi/activatedUserList",
  userbalance: "adminapi/userbalance",
  useraddress: "adminapi/useraddress",
  get_all_user_deposit: "withdraw/get_all_user_deposit",
  get_all_user_withdraw: "withdraw/get_all_user_withdraw",
  admin_withdraw_approve: "withdraw/admin_withdraw_approve",
  changeUserAccountStatus: "adminapi/changeUserAccountStatus",
  changeEmail: "adminapi/changeEmail",
  Addbalance: "adminapi/Addbalance",

  cms_update: "adminapi/cms_update",
  allCurrencyListCrypto: "adminapi/allCurrencyListCrypto",
  currency_get: "adminapi/currency_get",
  getprofit: "adminapi/getprofit",
  cms_list: "adminapi/cms_list",
  currencyAddUpdate: "adminapi/currencyAddUpdate",
  cms_get: "adminapi/cms_get",
  currency_get: "adminapi/currency_get",
  mailtemplate_list: "adminapi/mailtemplate_list",

  mailtemplate_get: "adminapi/mailtemplate_get",
  getAdminProfile: "adminapi/admindetails",
  get_sitedata: "adminapi/get_sitedata",
  update_settings: "adminapi/update_settings",
  updateProfile: "adminapi/updateProfile",
  updateTfa: "adminapi/updateTfa",
  checkPassword: "adminapi/check_password",
  stakeHistory: "adminapi/stakeHistory",
  get_all_giveaway: "users/get_all_xdlgiveawy",
  admin_giveaway_approve: "users/xdl_admin_approve",



};

export default service;
