import Header from "./Header";
import toast, { Toaster } from "react-hot-toast";
import React, { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useState from "react-usestateref";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { setAuthorization } from "../core/service/axios";

function Landing() {


  const initialFormValue = {
    email: "",
    password: "",
  };

  const navigate = useNavigate();

  const [emailValidate, setemailValidate, emailValidateref] = useState(false);
  const [passwordValidate, setpasswordValidate, passwordValidateref] =
    useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");
  const [siteLoader, setSiteLoader] = useState(false);

  const { email, password } = formValue;

  useEffect(() => {
      var token = localStorage.getItem("user_token");
      if (token) {
          navigate("/dashboard");
      }
  }, []);

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\s/g, "");
    let formData = { ...formValue, ...{ [name]: sanitizedValue } };
    setFormValue(formData);
    validate(formData);
  };

  const validate = async (values) => {
    const errors = {};
    const username = values.email.split("@")[0];

    if (!values.email) {
      errors.email = "Email is a required field!";
      setemailValidate(true);
    } else if (
      !/^[a-zA-Z0-9.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address!";
      setemailValidate(true);
    } else if (username.length < 4 || username.length > 30) {
      errors.email = "Email Username must be between 4-30 characters long";
      setemailValidate(true);
    } else if (!/^[a-zA-Z0-9.]+$/i.test(username)) {
      errors.email =
        " Only letters (a-z), numbers (0-9), and periods (.) are allowed in the username";
      setemailValidate(true);
    } else if (!/[a-zA-Z]/.test(username)) {
      errors.email = "Email username must contain at least one letter (a-z)";
      setemailValidate(true);
    } else if (values.email.length > 50) {
      errors.email = "Email address is too long!";
      setemailValidate(true);
    } else if (values.password == "") {
      setemailValidate(false);
      setpasswordValidate(true);
      errors.password = "password is required !";
    } else if (values.password.length < 5 || values.password.length > 25) {
      setpasswordValidate(true);
      errors.password = "Password should not below 5 above 25 letters !";
    }
    //  else if (!values.password.match(/[a-z]/g)) {
    //     setpasswordValidate(true);
    //     errors.password = "Please enter at least lower character !";
    // } else if (!values.password.match(/[A-Z]/g)) {
    //     setpasswordValidate(true);
    //     errors.password = "Please enter at least upper character !";
    // } else if (!values.password.match(/[0-9]/g)) {
    //     setpasswordValidate(true);
    //     errors.password = "Please enter at One digit character !";
    // } else if (!values.password.match(/[!@#$%^&*]/g)) {
    //     setpasswordValidate(true);
    //     errors.password = "Please enter at least one special character !";
    // } 
    else {
      setpasswordValidate(false);
    }

    setvalidationnErr(errors);
    return errors;
  };

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const formSubmit = async () => {
    validate(formValue);
    if (
      emailValidateref.current === false &&
      passwordValidateref.current === false
    ) {
      console.log(formValue);
      var data = {
        apiUrl: apiService.adminlogin,
        payload: formValue,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      setFormValue(initialFormValue);
      if (resp?.tfa == 1) {
        localStorage.setItem("socket_token", resp.token);
        // navigate("/verify-2fa", {
        //   state: {
        //     socketToken: resp?.email,
        //   },
        // });
        // window.location.href = "/verify-2fa"
        navigate("/verify-2fa");
      } else {
        if (resp.status == true) {
          setFormValue(initialFormValue);
          toast.success(resp.Message);
          console.log(resp, "[--=-=resp");
          await setAuthorization(resp.token);
          localStorage.setItem("user_token", resp.token);
          localStorage.setItem("tfa_status", resp.tfa);
          localStorage.setItem("socket_token", resp.socketToken);
          localStorage.setItem("jwNkiKmttscotlox", resp.jwNkiKmttscotlox);
          navigate("/dashboard");
        } else {
          setbuttonLoader(false);
          toast.error(resp.Message);
        }
      }


    } else {
      console.log("Required all fields")
    }
  };



  return (
    <div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 4000,
        }}
      />
      <Header />
      <div className='admin_login_card'>
        <div className='container'>
          <div className='row mark_pagi'>
            <div className='col-lg-6'>
              <div className='admin_login_main'>
                <div className='hero_subhead'>
                  <span className='hero_head text-align-center'>
                    Log In
                  </span>
                  <div className='ycho_inner'>
                    <div className='hotpic_bot_hd'>
                      <span className='hero_sub_inner'>Email</span>
                      <input className='admin_login_imput'
                        placeholder='Enter email'
                        type="text"
                        name="email"
                        value={email}
                        onChange={handleChange} />

                      {validationnErr && validationnErr.email && (
                        <p className="errorcss">{validationnErr.email}</p>
                      )}
                    </div>
                    <div className='hotpic_bot_hd'>
                      <span className='hero_sub_inner'>Password</span>
                      <input className='admin_login_imput'
                        placeholder='Enter password'
                        type={inputType}
                        name="password"
                        value={password}
                        minLength={6}
                        maxLength={25}
                        onChange={handleChange} />

                      {validationnErr && validationnErr.password && (
                        <p className="errorcss">{validationnErr.password}</p>
                      )}
                    </div>
                  </div>
                  <div className='d-flex justify-content-center w_100'>
                    <div className='lan_had_con'>
                      {!buttonLoader ? (
                        <span className='con_lan_con' onClick={formSubmit} >Submit</span>
                      ) : (
                        <span className='con_lan_con' >Loading ...</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
